.modal-background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: auto;
  z-index: 9999;
}

.modal {
  max-width: 350px;
  max-height: 550px;
  height: 90%;
  width: 90%;
  background-color: white;
  border: 1px solid black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: relative;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-header {
  text-align: center;
}

.modal-header h1 {
  margin: 0;
  margin: 5px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.modal-body .button, 
.modal-body .p, 
.modal-body .input {
  margin: 5px;
}

.modal-footer {
  text-align: center;
  width: 100%;
}

.winner-text h2 {
  display: inline;
  font-weight: 600;
}

.winner-text .winner-name {
  font-weight: 700;
}

.button {
  width: 150px;
  height: 50px;
  background-color: #f9fbf8;
  color: inherit;
  border: 2px solid black;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 20px;
  font-weight: 700;
  box-shadow: -4px 4px 0px 0px rgba(0,0,0,1);
}

.input {
  width: 125px;
  height: 50px;
  background-color: #f9fbf8;
  color: inherit;
  border: 2px solid black;
  padding: 0;
  font: inherit;
  outline: inherit;
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
}

.button:active, .button:hover {
  transform: translateY(2px);
  user-select: none;  
  box-shadow: -4px 2px 0px 0px rgba(0,0,0,1);
  -webkit-tap-highlight-color: transparent;
}

.colour-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
  width: 180px;
}

.colour-picker-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  border: 2px solid black;
}

.colour-picker-option.picked {
  box-shadow: 0px 0px 0px 2px rgba(0,0,0,1);
}

.colour-picker-option:hover {
  transform: translateY(2px);
}
