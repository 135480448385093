/* https://coolors.co/palette/539e3f-2b9543-d5ddee-7f8fb8-f9fbf8-91bdd0-4b626b */
/* TODO Move to individual style sheets */
html, #root, .canvas{
  width: 100%;
  height: 100%;
}

.content {
  margin: auto;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.game {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-bottom: 70px;
  padding-top: 30px;
}

.game-board {
  position: relative;
  width: fit-content;
}

body {
  overscroll-behavior: none;
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #f9fbf8;
  background-image: radial-gradient(rgb(228, 228, 228) 1px, transparent 2px),
                    radial-gradient(rgb(228, 228, 228) 1px, transparent 2px);
  background-size: 30px 30px;
  background-position: 0 0, 60px 60px;
}

.tile {
  background: #91BDD0;
  border: 0px;
  font-size: 24px;
  line-height: 15px;
  height: 34px;
  margin: 1px;
  padding: 0;
  text-align: center;
  width: 34px;
  flex-shrink: 0;
  border-radius: 3px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  user-select: none;
}

.tile.hidden {
  background: #91BDD0;
  border: 0px;
}

.tile.hidden:active,
.tile.hidden:hover {
  transform: translateY(1px);
}

@keyframes vibrate {
  0% { transform:  rotate(0); }
  50% { transform: rotate(-5deg); }
  100% { transform: rotate(5deg); }
}

@keyframes fall {
  100% {
    opacity: 0%;
    transform: translateY(50px);
  }
}

.vibrate {
  /* animation: vibrate 0.3s linear infinite, fall 2s forwards !important; */
  animation: vibrate 0.2s linear infinite !important;
}

.tile.revealed {
  background: #f9fbf8 !important;
  border: 1px solid #bcc1db;
  animation: revealTile 0.1s;
}

@keyframes revealTile {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    background: #f9fbf8;
    border: 1px solid #91BDD0;
  }
}

.board-row:not(:last-child) {
  width: fit-content;
}

.board-row {
  width: fit-content;
}

.board-row {
  display: flex;
}

.board-stats {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  user-select: none;
}

.game-header, .game-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 1;
}

.game-header {
  height: 10px;
  top: 0;
}

.game-footer {
  bottom: 0;
  margin-bottom: 10px;
  user-select: none;
}

.left, .center, .right {
  text-align: center;
  margin: 5px;
}

.left, .right {
  flex: 1;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.game-footer .center {
  margin: 0px;
}

.center {
  width: 500px;
}

@media (max-width: 500px) {
  .center {
    width: 100%;
  }
}

.starting-countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-size: 50px;
  user-select: none;
}

.starting-countdown.zoom-in {
  animation: startingCountdownZoomIn 0.5s;
}

.progress-bars {
  border-radius: 5px;
  background-color: #f9fbf8;
  font-weight: 600;
  padding: 10px;
}

.progress-bars .row {
  display: flex;
  height: 20px;
  align-items: center;
}

.progress-bars .name {
  width: 20%;
  text-align: left;
  padding-left: 5px;
}
.progress-bars .percentage {
  width: 30px;
  text-align: right;
  padding-right: 5px;
}

.progress-bars .progress-bar {
  width: 70%;
  height: 15px;
  background-color: #d1d5e2;
  border-radius: 5px;
}

.progress {
  height: 100%;
  border-radius: 5px;
}

@keyframes almostComplete {
  0% { transform:  rotate(0); }
  10% { transform: rotate(-0.5deg); }
  20% { transform: rotate(0.5deg); }
  30% { transform: rotate(-0.5deg); }
  40% { transform: rotate(0.5deg); }
  50% { transform: rotate(-0.5deg); }
  60% { transform: rotate(0.5deg); }
  70% { transform: rotate(-0.5deg); }
  80% { transform: rotate(0.5deg); }
  90% { transform: rotate(-0.5deg); }
  100% { transform: rotate(0.5deg); }
}

.almost-complete {
  animation: almostComplete 0.5s linear infinite;
}

@keyframes startingCountdownZoomIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
